<template>
<h1 class="text-center">ModifiersPlugin</h1>
</template>

<script>
export default {
  name: "ModifiersPlugin"
}
</script>

<style scoped>

</style>